import Vue from 'vue'
import Router from 'vue-router'
import httpServ from '../services/HTTPService'

Vue.use(Router)

export const routes = [{
  //DCRequestActivation
  path: '/dCRequestActivation',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'dCRequestActivation',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/DCRequestActivation.vue')
  }]
},
{
  //SCRequestActivation
  path: '/stateCoor',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'stateCoor',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/StateCoor.vue')
  }]
},
{
  //DCSummary
  path: '/dcStatus',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'dcStatus',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/DCStatus.vue')
  }]
},
{
  //DCSummary
  path: '/farmersStatsLGA',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'farmersStatsLGA',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/FarmersStatsLGA.vue')
  }]
},
{
  //FarmersStatsCommodity
  path: '/farmersStatsCommodity',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'farmersStatsCommodity',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/FarmersStatsCommodity.vue')
  }]
},
{
  //DataClerk
  path: '/dataClerk',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'dataClerk',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/DataClerk.vue')
  }]
},
{
  path: '/cluster',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'cluster',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/Cluster.vue')
  }]
},
{
  path: '/commodity',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'commodity',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/Commodity.vue')
  }]
},
{
  path: '/defaultChangePassword',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'defaultChangePassword',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/DefaultChangePassword.vue')
  }]
},
{
  //Original
  path: '/',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'signin',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/SignIn.vue')
  }]
},{
  path: '/dashboard',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/Dashboard.vue')
  }]
},
///////////////////////////////////////////////

///////////////////////////////////////////////
{
  path: '*',
  component: () => import(/* webpackChunkName: "layout-error" */ '@/layouts/ErrorLayout.vue'),
  children: [{
    path: '',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '@/pages/error/NotFoundPage.vue')
  }]
}]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})

/**
 * Before each route update
 */
/*router.beforeEach((to, from, next) => {
  return next()
})*/
router.beforeEach(async (to,from,next) => {
  const data = await httpServ.getSessionJson('userData')
  const haveAccess = data !== null ? true : false
  const publicPages = ['signin','logout']
  const authRequired = !publicPages.includes(to.name)

  if (authRequired) {
    if (!haveAccess) {
      return next('/')
    }

    return next()
  } else if (to.name === 'logout') {
    httpServ.clearSession()
    window.location.href = 'https://nfmp.gov.ng'

    return false
  } else {
    return next()
  }

  //return false
})

/*router.beforeEach((to, from, next) => {
  const memberData = httpServ.getSessionJson('memberData')

  if (to.name === 'signin' || to.name === 'dummyDashboard') {
    if (memberData !== null) {
      next()

      return
    }
    next('/signin')

    return
  } else {
    next()

    return
  }
})*/

/**
 * After each route update
 */
router.afterEach((to, from) => {
})

export default router
